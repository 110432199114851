<script setup>
import { onMounted, ref } from 'vue';

const otpProps = defineProps({
    length: {
        type: Number,
        default: 4
    },
    class: {
        type: String,
        default: 'border rounded-md w-10 p-2 text-center'
    }
})

const otpArray = ref([]);
const container = ref();
const otpEmit = defineEmits(['entered'])

onMounted(() => {
    for (let i = 0; i < otpProps.length; i++) {
        otpArray[i] = null
    }
})
function handleEnter(e, i) {
    const children = container.value.children;
    const keypressed = e.key
     
    checkOTP()

    if(i > 0 && (keypressed === 'Backspace' || keypressed == 'Delete')) {
        otpArray.value[i] = null;
        children[i-1].focus()
    } else {
        const matched = keypressed.match(/^[0-9]$/)
        if(!matched) {
            otpArray.value[i] = null
            return
        } else if(i < otpProps.length - 1) {
            children[i+1].focus()
        } 
    }

    checkOTP()

}

function checkOTP() {
    const children = container.value.children;

    let flag = true;

    for (let i = 0; i < otpProps.length - 2; i++) {
        
        children[i].classList.remove('border-red-500')

        if(otpArray.value[i] == null) {
            children[i].classList.add('border-red-500')
            flag = false
        } else {
            children[i].classList.remove('border-red-500')
        }
    }
    
    if(flag) {
        otpEmit('entered', otpArray.value.join(''))
    }
}

</script>
<template>
    <div ref="container" class="flex gap-4 items-center h-full">
        <input type="tel" v-for="n in length" :key="n"
        pattern="\d*" 
        maxlength="1" 
        @keyup="(e) => handleEnter(e, n-1)"
        v-model="otpArray[n - 1]" :class="class" 
        />
    </div>
</template>