<script setup>
import { onMounted , computed, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';

const page = usePage();

const room = page.props.room;

const displayDays = ref(0);
const displayHours = ref(0);
const displayMinutes = ref(0);
const displaySeconds = ref(0);

const showTimer = ref(false);

const _seconds = computed(() => {
    return 1000;
});

const _minutes = computed(() => {
    return _seconds.value * 60;
});

const _hours = computed(() => {
    return _minutes.value * 60;
});

const _days = computed(() => {
    return _hours.value * 24;
});

onMounted(() => {
    if(getDistance() > 0) {
        showTimer.value = true;
        showRemaining()
    }
});

function getDistance() {
    const now = new Date();
    const end = new Date(room.release_date);
    const distance = end.getTime() - now.getTime();
    
    if(distance < 0) {
        return 0
    }

    return distance;
}
function showRemaining()
{
    const timer = setInterval(() => {
        const distance = getDistance();
        
        if(distance < 0) {
            clearInterval(timer);
        }

        var days = Math.floor(distance / _days.value);
        var hours = Math.floor((distance % _days.value) / _hours.value);
        var minutes = Math.floor((distance % _hours.value) / _minutes.value);
        var seconds = Math.floor((distance % _minutes.value) / _seconds.value);
        

        displayDays.value = days < 10 ? "0" + days : days; 
        displayHours.value = hours < 10 ? "0" + hours : hours; 
        displayMinutes.value = minutes < 10 ? "0" + minutes : minutes; 
        displaySeconds.value = seconds < 10 ? "0" + seconds : seconds; 
    }, 1000)
}
</script>

<template>
    <div v-if="showTimer">
        <p class="text-center text-white mt-2">{{ $t('Coming soon') }}</p>
        <section class="flex text-2xl justify-center content-center font-sans text-white">
            <div class="days text-center">
                <span>{{ displayDays }}</span>
                <div class="label text-xs">{{ $t('Days') }}</div>
            </div>
            <span>:</span>
            <div class="hours text-center">
                {{ displayHours }}
                <div class="label text-xs">{{ $t('Hours') }}</div>
            </div>
            <span>:</span>
            <div class="minutes text-center">
                {{ displayMinutes }}
                <div class="label text-xs">{{ $t('Min') }}</div>
            </div>
            <span>:</span>
            <div class="seconds text-center">
                {{ displaySeconds }}
                <div class="label text-xs">{{ $t('Sec') }}</div>
            </div>
        </section>
    </div>
</template>

<style scoped>
.seconds {
    max-width: 60px;
}
</style>